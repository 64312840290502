@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base
{
  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-L.ttf');
    font-weight: 300;
  }

  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-R.ttf');
    font-weight: normal;
  }

  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-B.ttf');
    font-weight: bold;
  }

  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-LI.ttf');
    font-weight: 300;
    font-style: italic;
  }

  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-RI.ttf');
    font-weight: normal;
    font-style: italic;
  }

  @font-face
  {
    font-family: Ubuntu;
    src: url('./fonts/shared/Ubuntu-BI.ttf');
    font-weight: bold;
    font-style: italic;
  }

  @font-face
  {
    font-family: UbuntuMono;
    src: url('./fonts/shared/UbuntuMono-R.ttf');
    font-weight: normal;
  }

  @font-face
  {
    font-family: UbuntuMono;
    src: url('./fonts/shared/UbuntuMono-B.ttf');
    font-weight: bold;
  }

  @font-face
  {
    font-family: UbuntuMono;
    src: url('./fonts/shared/UbuntuMono-RI.ttf');
    font-weight: normal;
    font-style: italic;
  }

  @font-face
  {
    font-family: UbuntuMono;
    src: url('./fonts/shared/UbuntuMono-BI.ttf');
    font-weight: bold;
    font-style: italic;
  }

  *
  {
    @apply
      outline-none
      focus:outline-none
      ;
  }
}

@layer components
{
  @import 'react-toastify/dist/ReactToastify.css';

  *
  {
    @apply transition-colors;
  }

  .menu-open,
  .modal-open
  {
    @apply overflow-hidden;
  }

  .modal-overlay
  {
    @apply
      fixed
      inset-0
      transition-colors
      duration-200
      bg-black
      bg-opacity-0
      z-20
    ;
  }

  .modal-overlay.modal-open
  {
    @apply
      bg-opacity-20
    ;
  }

  .modal-overlay.modal-closing
  {
    @apply
      bg-opacity-0
    ;
  }

  .modal-content
  {
    @apply
      absolute
      top-1/2
      left-1/2
      right-auto
      bottom-auto
      transform
      -translate-x-1/2
      rounded
      opacity-0
      transition
      duration-200
      overflow-hidden
      flex
      flex-col
      bg-white
      border
      border-gray-300
      shadow-2xl
    ;
    --tw-translate-y: calc(-50% - 1rem);
    width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
  }

  .modal-open .modal-content
  {
    @apply
      -translate-y-1/2
      opacity-100
    ;
  }

  .modal-closing .modal-content
  {
    @apply
      opacity-0
    ;
    --tw-translate-y: calc(-50% + 1rem);
  }

  .lds-ripple
  {
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
  }

  .lds-ripple div
  {
    position: absolute;
    border: 0.15em solid currentColor;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2)
  {
    animation-delay: -.5s;
  }

  @keyframes lds-ripple
  {
    0%
    {
      top: 1em;
      left: 1em;
      width: 0;
      height: 0;
      opacity: 1;
      border-width: 0;
    }

    7%
    {
      border-width: .15em;
    }

    100%
    {
      top: 0;
      left: 0;
      width: 2em;
      height: 2em;
      opacity: 0;
    }
  }

  .square
  {
    aspect-ratio: 1;
  }

  .tooltip
  {
    @apply
      bg-black
      text-white
      rounded
      text-xs
      p-2
      py-1
      hidden
      pointer-events-none
      z-50
      shadow
      opacity-0
      ;
  }

  .tooltip.block
  {
    @apply opacity-80;
  }

  .tooltip .arrow
  {
    @apply absolute;
    background: inherit;
  }

  .tooltip[data-popper-placement=top] .arrow { @apply bottom-0; }
  .tooltip[data-popper-placement=bottom] .arrow { @apply top-0; }
  .tooltip[data-popper-placement=left] .arrow { @apply right-0; }
  .tooltip[data-popper-placement=right] .arrow { @apply left-0; }

  .tooltip .arrow::before
  {
    content: '';
    @apply
      w-1.5
      h-1.5
      transform-gpu
      -translate-x-1/2
      -translate-y-1/2
      rotate-45
      block
      absolute
      shadow
      ;
    opacity: unset !important;
    background: inherit;
  }

  table.table
  {
    @apply
      text-sm
      text-left
      ;
  }

  table.table thead tr
  {
    @apply
      border-b-2
      border-gray-300
      ;
  }

  table.table th,
  table.table td
  {
    @apply
      p-1
      ;
  }

  table.table tbody tr
  {
    @apply
      hover:bg-gray-200
      transition-colors
      ;
  }

  table.table tbody tr:nth-child(even)
  {
    @apply
      bg-gray-100
      bg-opacity-50
      hover:bg-gray-200
      hover:bg-opacity-100
      ;
  }

  select.appearance
  {
    @apply
      appearance-none
      pr-4
      bg-no-repeat
      ;
    background-image: data-uri(src/icons/more_vert_black_24dp.svg);
    background-size: auto 1rem;
    background-position: right 0 center;
  }
}

@layer utilities
{
  .stroke-behind
  {
    paint-order: stroke;
  }
}
